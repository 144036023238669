.contact__main {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
}


.contact-fontawesome-icon, .contact-fontawesome-icon-bigger {
    color: #C14953;
    font-size: 20px;
    margin-right: 15px;
}


.contact-fontawesome-icon:hover, .contact-fontawesome-icon-bigger:hover {
    color: #464646;
}

.contact-fontawesome-icon-bigger {
    font-size: 45px;
    cursor: pointer;
    margin: 0px 20px;
}

.contact-flex-tiles-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    /* box-shadow: 5px 5px 25px #ca4c58; */
    box-sizing: border-box;
}

.contact-flex-tiles-info {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.contact-flex-tiles-info > a {
    color: #a7a7a7;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-flex-getintouch {
    text-align: center;
    color: #a7a7a7;
    margin-bottom: 40px;
}

.contact__h1 {
    font-weight: normal;
    font-size: 3rem;
}