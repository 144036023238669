
.portfolio__main {
    justify-content: flex-start !important;
    align-items: center !important;
    flex-direction: column !important;
}

.portfolio__h1 {
    font-weight: normal;
    font-size: 3rem;
    margin-bottom: 0;
}

.portfolio__h2 {
    font-weight: normal;
    font-size: 2rem;
    color: #a7a7a7;
    margin-bottom: 100px;
}

.portfolio__span {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #a7a7a7;
}

@media (max-width: 1024px) {
    .portfolio__h1 {
        text-align: center;
        margin-top: 0;
    }

    .portfolio__h2 {
        text-align: center;
        margin-bottom: 0;
    }
}

.portfolio__item-slider {
    display: inline-block;
    height: 400px;
    width: 50vh;
    user-select: none;
    margin: 15px;
    text-align: center;
}

@media (max-width: 1024px) {
    .portfolio__item-slider {
        display: inline-block;
        width: 50vh;
        height: calc(100% - 30px);
        user-select: none;
        margin: 15px;
        text-align: center;
    }
}

.portfolio__item-img {    
    background-image: url(/static/media/athetop-preview.44f3f4a9.png);
    height: calc(100% - 100px);
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
    top: 0;
}

.portfolio__item-img:hover {
    top: -8px;
}

.portfolio__item-text {
    margin-top: 15px;
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
    position: relative;
    z-index: 1;
    display: inline-block;
}


.portfolio__item-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #C14953;
    transition: all .3s;
    border-radius: 50px;
    z-index: -1;
}

.portfolio__item-text:hover::before {
    width: 100%;
}

.portfolio__item-icon {
    margin-left: 10px;
    font-size: 20px;
}

.portfolio__project-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    font-size: 1.0rem;
    background-color: #f1f1f1;
}

.portfolio__project-details > div {
    width: 50%;
}

@media (max-width: 1024px) {
    .portfolio__project-details {
        padding: 20px;
        flex-direction: column;
    }

    .portfolio__project-details > div {
        width: 100%;
    }
}

.portfolio__details-p {
    margin-top: 30px;
}

.portfolio__details-images {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 15px;
    width: 100%;
    padding: 15px;
    align-items: flex-end;
    justify-content: flex-end;
}


@media (max-width: 1024px) {
    .portfolio__details-images {
        align-items: center;
        justify-content: center;
    }
}

.portfolio__details-images-item {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: 0.3s;
    margin-left: 10px;
}

.portfolio__details-images-item:hover {
    top: -10px;
}

.portfolio__image-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.portfolio__image-fullscreen > img {
    height: calc(100% - 100px);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}