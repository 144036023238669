.landing-page__main {
    justify-content: space-between;
}

.landing-page__h1 {
    font-weight: normal;
    font-size: 3rem;
    margin-top: 0;
}

.landing-page__h2 {
    font-weight: normal;
    font-size: 2rem;
    color: #a7a7a7;
}

.landing-page__span {
    font-size: 1rem;
    color: #a7a7a7;
}

.landing-page__picture-div {
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
}

.landing-page__picture-div::before {
    content: '';
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background-color: #c14953;
}

.landing-page__picture-div > img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
}

@media (max-width: 1024px) {
    .landing-page__picture-div {
        display: none;
    }

    .landing-page__h1 {
        margin-top: 0;
    }
    .landing-page__span {
      display: block;
    }
    
}