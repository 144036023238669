.buttonkko__button {
    font-family: 'Varela Round';    
    padding: 2rem 2.5rem;
    border-radius: 50px;
    font-size: 1.5rem;
    border: none;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    color: #ffffff;
    background-color: #C14953;
    outline: none;
    cursor: pointer;
    z-index: 1;
}

.buttonkko__button:hover {    
    padding: 2rem 3rem;
}

.buttonkko__button:focus {
    outline: none;
}


.buttonkko__button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #C14953;
    z-index: -2;
}

.buttonkko__button::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #000000;
    opacity: 0.1;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
}

.buttonkko__button:hover::before {
    width: 100%;
}