.about__main {
    justify-content: space-between;
    padding: 0 15%;
    align-items: flex-start;
}

@media (max-width: 1024px) {    
    .about__main {
        padding: 15px;
        flex-direction: column;
    }
}

.about__main > div {
    margin: 0 30px;
}

.about__side {
    max-width: 350px;
}

@media (max-width: 1024px) {    
    .about__side {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        margin: 15px 0 30px 0 !important;
    }
}

.about__text {
    padding-top: 70px;
}

@media (max-width: 1024px) {    
    .about__text {
        order: -1;
        padding-top: 0;
    }
}

.about__skills-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.about__skills-list > span {
    padding: 5px 10px;
    border: 1px solid #353535;
    border-radius: 20px;
    margin: 3px;
    font-size: 1rem;
    transition: 0.3s all;
    cursor: pointer;
    user-select: none;
}

.about__skills-list > span:hover {
    background-color: #c14953;
    border-color: #c14953;
}

.about__h1 {
    font-weight: normal;
    font-size: 3rem;
    margin-top: 0;
}

.about__p {
    font-weight: normal;
    font-size: 1.1rem;
    color: #a7a7a7;
}

.about__span {
    font-size: 1rem;
    color: #a7a7a7;
}

.about__side-title {
    text-align: center;
    margin: 15pxz 0;
    font-size: 1.2rem;
    color: #ffffff;
}

.about__picture-div {
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    margin-bottom: 35px;
}

.about__picture-div::before {
    content: '';
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background-color: #c14953;
}

.about__picture-div > img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
}

@media (max-width: 1024px) {

    .about__h1 {
        margin-top: 0;
    }
    .about__span {
      display: block;
    }
    
}