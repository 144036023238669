@font-face {
  font-family: 'Varela Round';
  src: url(./fonts/VarelaRound-Regular.ttf);
}

* {
  -webkit-overflow-scrolling: touch;
}

html, body, #root {
  font-size: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'Varela Round';
  overflow-x: hidden;
  background: #191a1d;
  position: relative;
}

html::before {
  display: none;
}

@media (max-width: 1024px) {
  html, body, #root {
    font-size: 80%;
  }
}

.index-header-options-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .index-header-options-right {
    margin-top: 70px;
    flex-direction: column;
    position: absolute;
    height: 0%;
    top: 0;
    justify-content: flex-start;
    background: #191a1d;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    transition: 0.3s;
  }

  .index-header-options-menu-opened {
    height: calc(100% - 70px);
  }
}

.index-header-title {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  padding: 15px 0;
}

.index-header-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 30%;
  height: 10px;
  background-color: #C14953;
  transition: all .3s;
  border-radius: 10px;
  z-index: 1;
}

.index-header-title:hover::after {
  width: 100%;
}

.common__main-section {
  background: #191a1d;
  width: 100%;
  height: calc(100% - 100px);
  color: #ffffff;
  overflow: auto;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: .5s;
  position: relative;
  left: 0;
}

@media (max-width: 1024px) {

  .common__main-section {
    height: calc(100% - 70px);
  }
}

.animation-absolute-next {
  position: absolute;
  top: 100px;
  left: 100%;
}

.animation-absolute-prev {
  position: absolute;
  top: 100px;
  left: -100%;
}

.animation-absolute-current {
  position: absolute;
  top: 100px;
  left: 0%;
}

@media (max-width: 1024px) {
  .animation-absolute-next {
    position: absolute;
    top: 70px;
  }

  .animation-absolute-prev {
    position: absolute;
    top: 70px;
  }

  .animation-absolute-current {
    position: absolute;
    top: 70px;
  }
}

.animation-transition {
  transition: .5s;
}

.animation-left-0 {
  left: 0%;
}

.index-logo {
  width: 75px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.index-header-options {
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  background: #191a1d;
}

@media (max-width: 1024px) {
  .index-header-options {
    flex-direction: column;
    justify-content: flex-start;
    height: 70px;
  }
}

.index-header-options-item:first-of-type {
  margin-left: 15px;
}

.index-header-options-item {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5em;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  padding: 5px 10px;
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .index-header-options-item:first-of-type {
    margin-top: 20px;
    margin-left: 0px;
  }

  .index-header-options-item {
    margin-left: 0;
    margin-top: 10px;
    font-size: 3em;
  }
}

.index-header-close {
  color: #ffffff;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 30px;
  display: none;
}

@media (max-width: 1024px) {
  .index-header-close {
    display: block;
  }
}

.index-header-options-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #C14953;
  transition: all .3s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: -1;
}

.index-header-options-item:hover::before {
  height: 100%;
}

.index-header-options-flags {
  width: 30px;
  margin-left: 25px;
  cursor: pointer;
  transition: all 0.3s;
}

@media (max-width: 1024px) {
  .index-header-options-flags {
    margin-top: 20px;
    margin-left: 0px;
    flex-direction: column;
    order: 9;
  }
}

.index-header-options-flags:hover {
  width: 35px;
}

.div-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}