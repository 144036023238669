body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Varela Round';
  src: url(../../static/media/VarelaRound-Regular.159cb67f.ttf);
}

* {
  -webkit-overflow-scrolling: touch;
}

html, body, #root {
  font-size: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'Varela Round';
  overflow-x: hidden;
  background: #191a1d;
  position: relative;
}

html::before {
  display: none;
}

@media (max-width: 1024px) {
  html, body, #root {
    font-size: 80%;
  }
}

.index-header-options-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (max-width: 1024px) {
  .index-header-options-right {
    margin-top: 70px;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    height: 0%;
    top: 0;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    background: #191a1d;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .index-header-options-menu-opened {
    height: calc(100% - 70px);
  }
}

.index-header-title {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  padding: 15px 0;
}

.index-header-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 30%;
  height: 10px;
  background-color: #C14953;
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: 10px;
  z-index: 1;
}

.index-header-title:hover::after {
  width: 100%;
}

.common__main-section {
  background: #191a1d;
  width: 100%;
  height: calc(100% - 100px);
  color: #ffffff;
  overflow: auto;
  padding: 0 2rem;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-transition: .5s;
  transition: .5s;
  position: relative;
  left: 0;
}

@media (max-width: 1024px) {

  .common__main-section {
    height: calc(100% - 70px);
  }
}

.animation-absolute-next {
  position: absolute;
  top: 100px;
  left: 100%;
}

.animation-absolute-prev {
  position: absolute;
  top: 100px;
  left: -100%;
}

.animation-absolute-current {
  position: absolute;
  top: 100px;
  left: 0%;
}

@media (max-width: 1024px) {
  .animation-absolute-next {
    position: absolute;
    top: 70px;
  }

  .animation-absolute-prev {
    position: absolute;
    top: 70px;
  }

  .animation-absolute-current {
    position: absolute;
    top: 70px;
  }
}

.animation-transition {
  -webkit-transition: .5s;
  transition: .5s;
}

.animation-left-0 {
  left: 0%;
}

.index-logo {
  width: 75px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.index-header-options {
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
  background: #191a1d;
}

@media (max-width: 1024px) {
  .index-header-options {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 70px;
  }
}

.index-header-options-item:first-of-type {
  margin-left: 15px;
}

.index-header-options-item {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5em;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  padding: 5px 10px;
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .index-header-options-item:first-of-type {
    margin-top: 20px;
    margin-left: 0px;
  }

  .index-header-options-item {
    margin-left: 0;
    margin-top: 10px;
    font-size: 3em;
  }
}

.index-header-close {
  color: #ffffff;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 30px;
  display: none;
}

@media (max-width: 1024px) {
  .index-header-close {
    display: block;
  }
}

.index-header-options-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #C14953;
  -webkit-transition: all .3s;
  transition: all .3s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: -1;
}

.index-header-options-item:hover::before {
  height: 100%;
}

.index-header-options-flags {
  width: 30px;
  margin-left: 25px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1024px) {
  .index-header-options-flags {
    margin-top: 20px;
    margin-left: 0px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-order: 9;
            order: 9;
  }
}

.index-header-options-flags:hover {
  width: 35px;
}

.div-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.buttonkko__button {
    font-family: 'Varela Round';    
    padding: 2rem 2.5rem;
    border-radius: 50px;
    font-size: 1.5rem;
    border: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    color: #ffffff;
    background-color: #C14953;
    outline: none;
    cursor: pointer;
    z-index: 1;
}

.buttonkko__button:hover {    
    padding: 2rem 3rem;
}

.buttonkko__button:focus {
    outline: none;
}


.buttonkko__button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #C14953;
    z-index: -2;
}

.buttonkko__button::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #000000;
    opacity: 0.1;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
}

.buttonkko__button:hover::before {
    width: 100%;
}
.landing-page__main {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.landing-page__h1 {
    font-weight: normal;
    font-size: 3rem;
    margin-top: 0;
}

.landing-page__h2 {
    font-weight: normal;
    font-size: 2rem;
    color: #a7a7a7;
}

.landing-page__span {
    font-size: 1rem;
    color: #a7a7a7;
}

.landing-page__picture-div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
}

.landing-page__picture-div::before {
    content: '';
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background-color: #c14953;
}

.landing-page__picture-div > img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
}

@media (max-width: 1024px) {
    .landing-page__picture-div {
        display: none;
    }

    .landing-page__h1 {
        margin-top: 0;
    }
    .landing-page__span {
      display: block;
    }
    
}
.about__main {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 15%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

@media (max-width: 1024px) {    
    .about__main {
        padding: 15px;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

.about__main > div {
    margin: 0 30px;
}

.about__side {
    max-width: 350px;
}

@media (max-width: 1024px) {    
    .about__side {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        margin: 15px 0 30px 0 !important;
    }
}

.about__text {
    padding-top: 70px;
}

@media (max-width: 1024px) {    
    .about__text {
        -webkit-order: -1;
                order: -1;
        padding-top: 0;
    }
}

.about__skills-list {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.about__skills-list > span {
    padding: 5px 10px;
    border: 1px solid #353535;
    border-radius: 20px;
    margin: 3px;
    font-size: 1rem;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.about__skills-list > span:hover {
    background-color: #c14953;
    border-color: #c14953;
}

.about__h1 {
    font-weight: normal;
    font-size: 3rem;
    margin-top: 0;
}

.about__p {
    font-weight: normal;
    font-size: 1.1rem;
    color: #a7a7a7;
}

.about__span {
    font-size: 1rem;
    color: #a7a7a7;
}

.about__side-title {
    text-align: center;
    margin: 15pxz 0;
    font-size: 1.2rem;
    color: #ffffff;
}

.about__picture-div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
    margin-bottom: 35px;
}

.about__picture-div::before {
    content: '';
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background-color: #c14953;
}

.about__picture-div > img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
}

@media (max-width: 1024px) {

    .about__h1 {
        margin-top: 0;
    }
    .about__span {
      display: block;
    }
    
}

.portfolio__main {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
    -webkit-align-items: center !important;
            align-items: center !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
}

.portfolio__h1 {
    font-weight: normal;
    font-size: 3rem;
    margin-bottom: 0;
}

.portfolio__h2 {
    font-weight: normal;
    font-size: 2rem;
    color: #a7a7a7;
    margin-bottom: 100px;
}

.portfolio__span {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #a7a7a7;
}

@media (max-width: 1024px) {
    .portfolio__h1 {
        text-align: center;
        margin-top: 0;
    }

    .portfolio__h2 {
        text-align: center;
        margin-bottom: 0;
    }
}

.portfolio__item-slider {
    display: inline-block;
    height: 400px;
    width: 50vh;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin: 15px;
    text-align: center;
}

@media (max-width: 1024px) {
    .portfolio__item-slider {
        display: inline-block;
        width: 50vh;
        height: calc(100% - 30px);
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        margin: 15px;
        text-align: center;
    }
}

.portfolio__item-img {    
    background-image: url(/static/media/athetop-preview.44f3f4a9.png);
    height: calc(100% - 100px);
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    top: 0;
}

.portfolio__item-img:hover {
    top: -8px;
}

.portfolio__item-text {
    margin-top: 15px;
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
    position: relative;
    z-index: 1;
    display: inline-block;
}


.portfolio__item-text::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #C14953;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 50px;
    z-index: -1;
}

.portfolio__item-text:hover::before {
    width: 100%;
}

.portfolio__item-icon {
    margin-left: 10px;
    font-size: 20px;
}

.portfolio__project-details {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 100px;
    font-size: 1.0rem;
    background-color: #f1f1f1;
}

.portfolio__project-details > div {
    width: 50%;
}

@media (max-width: 1024px) {
    .portfolio__project-details {
        padding: 20px;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .portfolio__project-details > div {
        width: 100%;
    }
}

.portfolio__details-p {
    margin-top: 30px;
}

.portfolio__details-images {
    display: -webkit-flex;
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 15px;
    width: 100%;
    padding: 15px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}


@media (max-width: 1024px) {
    .portfolio__details-images {
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.portfolio__details-images-item {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    top: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-left: 10px;
}

.portfolio__details-images-item:hover {
    top: -10px;
}

.portfolio__image-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    z-index: 999;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: auto;
}

.portfolio__image-fullscreen > img {
    height: calc(100% - 100px);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.contact__main {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    font-size: 1rem;
}


.contact-fontawesome-icon, .contact-fontawesome-icon-bigger {
    color: #C14953;
    font-size: 20px;
    margin-right: 15px;
}


.contact-fontawesome-icon:hover, .contact-fontawesome-icon-bigger:hover {
    color: #464646;
}

.contact-fontawesome-icon-bigger {
    font-size: 45px;
    cursor: pointer;
    margin: 0px 20px;
}

.contact-flex-tiles-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 600px;
    /* box-shadow: 5px 5px 25px #ca4c58; */
    box-sizing: border-box;
}

.contact-flex-tiles-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 40px;
}

.contact-flex-tiles-info > a {
    color: #a7a7a7;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.contact-flex-getintouch {
    text-align: center;
    color: #a7a7a7;
    margin-bottom: 40px;
}

.contact__h1 {
    font-weight: normal;
    font-size: 3rem;
}
